import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Carousel, Slide } from "vue-carousel-variable-width";
import DfStore from "@/vue/domain/store/df-store";
const DfRecipeCardComponent = () => Utils.externalComponent2("df-recipe-card");

@Component({
  components: {
    DfRecipeCard: DfRecipeCardComponent,
    Carousel,
    Slide,
  },
})
export default class DfSectionRecipesComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get recipes(): Array<DfContent> {
    const storeRecipes: Array<DfContent> =
      this.$store.getters.storeContents.filter(
        (content: DfContent) => content.type.code === "RCP"
      );

    const brandRecipes: Array<DfContent> =
      this.$store.getters.brandContents.filter(
        (content: DfContent) => content.type.code === "RCP"
      );

    const recipes: Array<DfContent> = [...storeRecipes, ...brandRecipes].sort(
      (contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1)
    );

    return recipes.slice(0, 10);
  }

  private goToRecipe(recipe: DfContent) {
    this.$router.push({ name: "recipe", params: { storeAlias: this.currentStore.alias, recipeAlias: recipe.alias } });
  }
}
